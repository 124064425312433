<template>
  <section class="container pb-5">
    <section-title>
      <avatar :imgUrl="user.info.avatar_url" size="50px" rounded />
      <h5 class="ml-4 mb-0">{{ user.info.name }}</h5>
      <router-link to="/merchant/settings" class="ml-auto">
        <btn class="btn-outline-secondary">
          <gear-svg />
          <span class="ml-2">{{ $t('dashboard.btn') }}</span>
        </btn>
      </router-link>
    </section-title>
    <div class="card px-5 py-4 d-flex flex-row" style="border: 1px solid #253043;">
      <div class="text-white" style="flex: 1 33.333%">
        <div class="mb-3">
          <dollar-svg />
          <span class="ml-3">{{ $t('dashboard.top.todayprice') }}</span>
        </div>
        <div class="d-flex align-items-center" style="font-size:36px">
          <span v-if="!topData"></span>
          <span v-if="topData">{{$currencySign}}{{ topData['24_hour'].total_price }}</span>
          <span v-if="topData" class="p-2 ml-3 text-10" style="background:rgba(57, 186, 20, 0.4);border-radius: 8px;">
            {{ todayPriceTrend.sign }} {{$currencySign}}{{ todayPriceTrend.value }}
          </span>
        </div>
      </div>
      <div class="text-white" style="flex: 1 33.333%">
        <div class="mb-3">
          #
          <span class="ml-3">{{ $t('dashboard.top.todayoffer') }}</span>
        </div>
        <div class="d-flex align-items-center" style="font-size:36px">
          <span v-if="topData">{{ topData['24_hour'].count }}</span>
          <span v-if="topData" class="p-2 ml-3 text-10" style="background:rgba(251, 191, 36, 0.4);border-radius: 8px;">
            {{ todayOfferTrend.sign }} {{ todayOfferTrend.value }}
          </span>
        </div>
      </div>
      <div class="text-white" style="flex: 1 33.333%">
        <div class="mb-3">
          <dollar-svg />
          <span class="ml-3">{{ $t('dashboard.top.historical') }}</span>
        </div>
        <div class="d-flex align-items-center" style="font-size:36px">
          <span v-if="topData">{{$currencySign}}{{ topData.all.total_price }}</span>
        </div>
      </div>
    </div>

    <div class="card mt-4">
      <div class="d-flex align-items-center">
        <slide-chip-select class="" v-model="timeRangeSelected" :options="timeRange" />
        <div class="ml-auto px-4">
          <span class="text-white">{{ $t('dashboard.chart.legend.num') }}</span>
          <span class="text-warning text-12 pl-2">{{ timeRangeSelectedCount }}</span>
        </div>
        <div class="px-4">
          <span class="text-white">{{ $t('dashboard.chart.legend.tot') }}</span>
          <span class="pl-2 text-12 text-primary">{{$currencySign}} {{ timeRangeSelectedTotal }}</span>
        </div>
      </div>
      <v-chart :options="line" :theme="line.theme" ref="chart" />
    </div>

    <div class="mt-4">
      <section-title>
        <tabs v-model="tab" :options="tabOption" size="md" withBorder />
      </section-title>
      <div class="d-flex">
        <drop-down class="" v-model="offerStatusFilter" :data="offerStatus">
          <template v-slot:title="{ selected }">
            <all-svg />
            <span>{{ selected.label }}</span>
          </template>
          <template v-slot:all>
            <all-solid-svg class="mr-2" />
          </template>
        </drop-down>
        <drop-down-price-range class="ml-4" v-model="priceRange" />

        <search-input-new class="ml-auto" :placeholder="$t('dashboard.table.search')" @enter="onSearch" />
      </div>

      <my-table
        class="mt-4 text-9"
        :data="filteredTableItems"
        :loading="loading"
        :cols="tableCols"
        :perPage="perPage"
        @pageChange="onPageChange"
        :total="tableItemsTotal"
      >
        <template v-slot:user="{ item }">
          <div class="d-flex align-items-center">
            <avatar :imgUrl="item.sender.avatar_url" />
            <div class="pl-2" style="width:114px">
              <div class="ellipsis">{{ item.sender.name }}</div>
              <div class="text-secondary">ID: {{ item.sender.id }}</div>
            </div>
          </div>
        </template>

        <template v-slot:items="{ item }">
          <div class="d-flex align-items-center">
            <div class="">
              <div class="">{{ item.sender_inventories[0].item.name }}</div>
              <div class="text-secondary">共 {{ item.sender_inventories.length }} 件</div>
            </div>
            <chevron-right-circle-svg
              class="ml-auto mr-1 pointer"
              style="flex:0 0 24px"
              :id="`tooltip-target-${item.offer_id}`"
            />
          </div>
          <b-tooltip :target="`tooltip-target-${item.offer_id}`" triggers="hover">
            <div class="text-left p-2">
              <div class="d-flex" v-for="(i, k) in item.sender_inventories" :key="k">
                <div class="ellipsis">{{ i.item.name }}</div>
                <div class="ml-2">x1</div>
                <div class="ml-2">{{$currencySign}}{{ i.price }}</div>
              </div>
            </div>
          </b-tooltip>
        </template>

        <template v-slot:num="{ item }">
          <span class="">{{ item.offer_id }}</span>
        </template>

        <template v-slot:sender_total_price="{ item }">
          <span class="">{{$currencySign}} {{ item.sender_total_price }}</span>
        </template>

        <template v-slot:time="{ item }">
          <span class=""> {{ new Date(item.updated_at).toLocaleString() }}</span>
        </template>

        <template v-slot:status="{ item }">
          <tick-svg v-if="item.status === 20" />
          <cross-circle-svg v-if="item.status === 50" />
          <span class="pl-1"> {{ item.status_name }}</span>
        </template>
      </my-table>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { MerchantService } from '@/services/api'
import { getSingleFromAccumulative } from '@/utils' 

const LINE = {
  theme: 'dark',
  backgroundColor: 'transparent',
  color: ['#FBBF24', '#2EDE34'],
  title: {
    text: 'Stacked Line',
  },
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    // data: ['Email', 'Union Ads'],
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  toolbox: {
    feature: {
      saveAsImage: {},
    },
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    axisLine: {
      lineStyle: {
        color: ['#253043'],
      },
    },
  },
  yAxis: [
    {
      type: 'value',
      axisLine: {
        lineStyle: {
          color: ['#253043'],
        },
      },
      nameTextStyle: {
        color: '#FBBF24',
      },
    },
    {
      type: 'value',
      axisLine: {
        lineStyle: {
          color: ['#253043'],
        },
      },
      nameTextStyle: {
        color: '#2EDE34',
      },
    },
  ],
}

export default {
  name: 'Dashboard',
  data() {
    return {
      loading: false, 
      line: {
        ...LINE,
        series: [
          {
            name: this.$t('dashboard.chart.legend.num'),
            type: 'line',
            stack: 'Total',
            areaStyle: {
              color: '#FBBF2433',
            },
            yAxisIndex: 0,
            data: [],
          },
          {
            name: this.$t('dashboard.chart.legend.tot'),
            type: 'line',
            stack: 'Total',
            areaStyle: {
              color: '#2EDE3433',
            },
            yAxisIndex: 1,
            data: [],
          },
        ],
      },
      timeRangeSelectedTotal: '',
      timeRangeSelectedCount: '',
      timeRangeSelected: { label: this.$t('dashboard.chip.24h'), value: '24h' },
      timeRange: [
        { label: this.$t('dashboard.chip.24h'), value: '24h' },
        { label: this.$t('dashboard.chip.7d'), value: '7d' },
        { label: this.$t('dashboard.chip.30d'), value: '30d' },
        { label: this.$t('dashboard.chip.all'), value: 'all' },
      ],
      priceRange: { type: 'usd', min: '', max: '' },
      offerStatusFilter: { label: this.$t('dashboard.table.menu1.all'), value: 'all' },
      offerStatus: [
        { label: this.$t('dashboard.table.menu1.all'), value: 'all' },
        { label: this.$t('dashboard.table.menu1.can'), value: 50 },
        { label: this.$t('dashboard.table.menu1.acc'), value: 20 },
      ],
      tableCols: [
        { label: this.$t('dashboard.table.cols.num'), value: 'offer_id', fixed: '100' },
        { label: this.$t('dashboard.table.cols.use'), value: 'user', fixed: '170' },
        { label: this.$t('dashboard.table.cols.con'), value: 'items' },
        { label: this.$t('dashboard.table.cols.tot'), value: 'sender_total_price', fixed: '180', sortable: true },
        { label: this.$t('dashboard.table.cols.tim'), value: 'time', fixed: '200' },
        { label: this.$t('dashboard.table.cols.sta'), value: 'status', fixed: '150', sortable: true },
      ],
      tableItems: [],
      tableItemsTotal: 0,
      perPage: 10,
      topData: null,
      tabOption: [{ label: this.$t('dashboard.history'), value: 'hist' }],
      tab: { label: this.$t('dashboard.history'), value: 'hist' },
      keyword: '',
    }
  },
  computed: {
    ...mapGetters(['user']),
    filteredTableItems() {
      let rv = this.tableItems
      if (this.priceRange) {
        if (this.priceRange.min) {
          rv = rv.filter(i => parseFloat(i.sender_total_price) >= this.priceRange.min)
        }
        if (this.priceRange.max) {
          rv = rv.filter(i => parseFloat(i.sender_total_price) <= this.priceRange.max)
        }
      }
      if (this.keyword) {
        rv = rv.filter(i => i.sender.name.includes(this.keyword) || i.offer_id.toString().includes(this.keyword))
      }
      /**
       * 10 进行中
       * 20 已接收
       * 30
       * 40
       * 50 已取消
       * 60 已拒绝
       */
      if (this.offerStatusFilter) {
        if (this.offerStatusFilter.value === 20) {
          rv = rv.filter(i => i.status === 20)
        }
        if (this.offerStatusFilter.value === 50) {
          rv = rv.filter(i => i.status === 50)
        }
      }
      return rv
    },
    todayPriceTrend() {
      if (!this.topData) return ''
      const today = parseFloat(this.topData['today'].total_price)
      const yesterday = parseFloat(this.topData['yesterday'].total_price) - today
      const diff = today - yesterday
      return { sign: diff > 0 ? '+' : '-', value: Math.abs(diff) }
    },
    todayOfferTrend() {
      if (!this.topData) return ''
      const today = this.topData['today'].count
      const yesterday = this.topData['yesterday'].count - today
      const diff = today - yesterday
      return { sign: diff > 0 ? '+' : '-', value: Math.abs(diff) }
    },
    xAxis() {
      let rv
      if (this.timeRange === '') {
      }
      return []
    },
  },
  mounted() {
    this.loadData()
  },
  watch: {
    timeRangeSelected(val) {
      this.updateChartData(val)
    },
  },
  methods: {
    onSearch(v) {
      this.keyword = v
    },
    onPageChange(v) {
      MerchantService.getStat(v)
        .then(({ data }) => {
          this.tableItems = data.data
          this.tableItemsTotal = data.total
          this.perPage = data.per_page
        })
        .catch(e => {})
        .finally(() => {
          this.loading = false
        })
    },
    loadData() {
      this.loading = true
      this.$refs.chart.showLoading({
        text: 'Loading...',
        color: '#32d229',
        textColor: 'rgba(255, 255, 255, 0.5)',
        maskColor: '#141a24',
        zlevel: 0,
      })

      MerchantService.getStat(1)
        .then(({ data }) => {
          this.tableItems = data.data
          this.tableItemsTotal = data.total
          this.perPage = data.per_page
        })
        .catch(e => {})
        .finally(() => {
          this.loading = false
        })

      MerchantService.getAllStat()
        .then(({ data }) => {
          this.topData = data
        })
        .catch(e => {})
        .finally(() => {
          this.loading = false
          this.updateChartData(this.timeRangeSelected)
          this.$refs.chart.hideLoading()
        })
    },
    updateChartData(val) {
      if (val.value === '24h') {
        const now = new Date(Date.now())
        const xAxis = []
        let yAxisCount = []
        let yAxisTotal = []
        const time = now.getTime()
        for (let i = 0; i <= 6; i++) {
          const newDT = new Date(time - 6 * 60 * 60 * 1000 * 4 + i * 60 * 60 * 1000 * 4)
          const hour = newDT.getHours()
          const min = newDT.getMinutes()
          xAxis.push(`${hour}:${min}`)
          let count = 0
          let total = 0
          this.topData['24_hour'].offers.forEach(i => {
            const createdDate = new Date(i.created_timestamp * 1000)
            const y = createdDate <= newDT
            if (y) {
              count++
              total += parseFloat(i.sender_total_price)
            }
          })
          yAxisCount.push(count)
          yAxisTotal.push(total)
        }
        yAxisCount = getSingleFromAccumulative(yAxisCount)
        yAxisTotal = getSingleFromAccumulative(yAxisTotal)
        this.line.xAxis.data = xAxis
        this.line.series[0].data = yAxisCount
        this.line.series[1].data = yAxisTotal
        this.timeRangeSelectedTotal = this.topData['24_hour'].total_price
        this.timeRangeSelectedCount = this.topData['24_hour'].count
      }

      if (val.value === '7d') {
        const now = new Date(Date.now())
        const xAxis = []
        let yAxisCount = []
        let yAxisTotal = []
        const time = now.getTime()
        const lang = this.$store.state.lang === 'cn' ? 'zh-CN' : 'en-US'
        for (let i = 0; i <= 6; i++) {
          const newDT = new Date(time - 6 * 60 * 60 * 1000 * 24 + i * 60 * 60 * 1000 * 24)
          const w = new Intl.DateTimeFormat(lang, { weekday: 'short' }).format(newDT)
          xAxis.push(w)
          let count = 0
          let total = 0
          this.topData['7_days'].offers.forEach(i => {
            const createdDate = new Date(i.created_timestamp * 1000)
            const y = createdDate <= newDT
            if (y) {
              count++
              total += parseFloat(i.sender_total_price)
            }
          })
          yAxisCount.push(count)
          yAxisTotal.push(total)
        }
        yAxisCount = getSingleFromAccumulative(yAxisCount)
        yAxisTotal = getSingleFromAccumulative(yAxisTotal)
        this.line.xAxis.data = xAxis
        this.line.series[0].data = yAxisCount
        this.line.series[1].data = yAxisTotal
        this.timeRangeSelectedTotal = this.topData['7_days'].total_price
        this.timeRangeSelectedCount = this.topData['7_days'].count
      }

      if (val.value === '30d') {
        const now = new Date(Date.now())
        const xAxis = []
        let yAxisCount = []
        let yAxisTotal = []
        const time = now.getTime()
        const lang = this.$store.state.lang === 'cn' ? 'zh-CN' : 'en-US'
        for (let i = 0; i <= 29; i++) {
          const newDT = new Date(time - 29 * 60 * 60 * 1000 * 24 + i * 60 * 60 * 1000 * 24)
          const day = new Intl.DateTimeFormat(lang, { day: 'numeric' }).format(newDT)
          xAxis.push(day)
          let count = 0
          let total = 0
          this.topData['30_days'].offers.forEach(i => {
            const createdDate = new Date(i.created_timestamp * 1000)
            const y = createdDate <= newDT
            if (y) {
              count++
              total += parseFloat(i.sender_total_price)
            }
          })
          yAxisCount.push(count)
          yAxisTotal.push(total)
        }
        yAxisCount = getSingleFromAccumulative(yAxisCount)
        yAxisTotal = getSingleFromAccumulative(yAxisTotal)
        this.line.xAxis.data = xAxis
        this.line.series[0].data = yAxisCount
        this.line.series[1].data = yAxisTotal
        this.timeRangeSelectedTotal = this.topData['30_days'].total_price
        this.timeRangeSelectedCount = this.topData['30_days'].count
      }

      if (val.value === 'all') {
        const now = new Date(Date.now())
        const xAxis = []
        let yAxisCount = []
        let yAxisTotal = []
        const time = now.getTime()
        const lang = this.$store.state.lang === 'cn' ? 'zh-CN' : 'en-US'
        let earliest = Date.now() / 1000
        this.topData['all'].offers.forEach(i => {
          if (i.created_timestamp < earliest) {
            earliest = i.created_timestamp
          }
        })
        const numOfDays = Math.ceil((Date.now() / 1000 - earliest) / (60 * 60 * 24)) + 1
        for (let i = 0; i <= numOfDays; i++) {
          const newDT = new Date(time - numOfDays * 60 * 60 * 1000 * 24 + i * 60 * 60 * 1000 * 24)
          const day = new Intl.DateTimeFormat(lang, { day: 'numeric' }).format(newDT)
          xAxis.push(day)
          let count = 0
          let total = 0
          this.topData['all'].offers.forEach(i => {
            const createdDate = new Date(i.created_timestamp * 1000)
            const y = createdDate <= newDT
            if (y) {
              count++
              total += parseFloat(i.sender_total_price)
            }
          })
          yAxisCount.push(count)
          yAxisTotal.push(total)
        }
        yAxisCount = getSingleFromAccumulative(yAxisCount)
        yAxisTotal = getSingleFromAccumulative(yAxisTotal)
        this.line.xAxis.data = xAxis
        this.line.series[0].data = yAxisCount
        this.line.series[1].data = yAxisTotal
        this.timeRangeSelectedTotal = this.topData['all'].total_price
        this.timeRangeSelectedCount = this.topData['all'].count
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  /* height: 100%; */
}
</style>
